import React, { useCallback, useState } from "react";
import { Button, Input } from "reactstrap";

const SearchOption = ({
  handleSearch,
  setSearchValue
}: {
  handleSearch: (value: string) => void;
  setSearchValue: (value: string) => void;
}) => {
  const [value, setValue] = useState("");

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        setSearchValue(value);
        handleSearch(value);
      }
    },
    [handleSearch, value, setSearchValue]
  );

  const handleButtonpress = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
      handleSearch(value);
    },
    [handleSearch, value]
  );

  return (
    <React.Fragment>
      <form className="app-search d-none d-md-block m-0 p-0">
        <div className="position-relative d-flex align-items-center">
          <Input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <span className="mdi mdi-magnify search-widget-icon"></span>
          <Button
            className="ml-auto"
            color="primary"
            onClick={handleButtonpress}
          >
            Search
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SearchOption;
