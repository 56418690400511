import { useState, useCallback } from "react";
import { Table } from "reactstrap";
import Pagination from "../../Common/Pagination";
import { useCustomersStore } from "../../../Store/store";
import { TCustomerModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import UiOffcanvas from "../../../pages/BaseUi/UiOffCanvas/UiOffCanvas";
import { Utility } from "../../../utils";
import ColumnSelectorDropdown from "./ColumnSelectorDropdown";
// import { updateCustomerDetails } from "../../../services";
// import { useMutation } from "@tanstack/react-query";
// import { toast } from "react-toastify";
import numeral from "numeral";
import "./CustomerTable.scss";

interface CustomersTableProps {
  customersResults: TCustomerModelJSON[];
  customersCount: number;
}

const CustomersTable: React.FC<CustomersTableProps> = ({
  customersResults,
  customersCount
}) => {
  const [limit, skip, setSkip] = useCustomersStore((state) => [
    state.limit,
    state.skip,
    state.setSkip
  ]);

  const [isRight, setIsRight] = useState<boolean>(false);
  const [setSelectedCustomer] = useCustomersStore((state) => [
    state.setSelectedCustomer
  ]);
  const [visibleColumns, setVisibleColumns] = useState({
    lastName: true,
    email: true,
    phoneNumber: true,
    avgSpend: true,
    visits: true,
    birthDay: false,
    createdOn: false,
    lastActivityOn: false
  });

  const toggleRightCanvas = useCallback(
    (e: any) => {
      setIsRight(!isRight);
      const customerId = e.currentTarget.getAttribute("data-id");
      const customer = customersResults.find(
        (customer) => customer?.id === customerId
      );
      setSelectedCustomer(customer as TCustomerModelJSON);
    },
    [setIsRight, isRight, customersResults, setSelectedCustomer]
  );

  const toggleColumnVisibility = (column: string) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column]
    }));
  };

  // const mutation = useMutation({
  //   mutationFn: async ({
  //     referenceId,
  //     allowed
  //   }: {
  //     referenceId: string;
  //     allowed: boolean;
  //   }) => {
  //     const queryObj = `${referenceId}`;
  //     const payload = {
  //       optIn: {
  //         marketing: {
  //           allowed: allowed
  //         }
  //       }
  //     };
  //     return await updateCustomerDetails(queryObj, payload);
  //   },
  //   onSuccess: () => {
  //     toast.success("Marketing preference updated successfully.");
  //   },
  //   onError: () => {
  //     toast.error("Failed to update marketing preference");
  //   }
  // });

  // const handleToggleMarketingAllowed = async (
  //   referenceId: string,
  //   allowed: boolean
  // ) => {
  //   mutation.mutate({ referenceId, allowed });
  // };

  return (
    <>
      <div className="table-header d-flex justify-content-end align-items-center mb-4">
        <ColumnSelectorDropdown
          visibleColumns={visibleColumns}
          toggleColumnVisibility={toggleColumnVisibility}
        />
      </div>
      <div className="table-responsive table-card">
        <Table className="align-middle table-nowrap mb-0 customer-table">
          <thead className="table-light">
            <tr>
              {/* <th scope="col">Marketing Allowed</th> */}
              <th scope="col">First Name</th>
              {visibleColumns.lastName && <th scope="col">Last Name</th>}
              {visibleColumns.email && <th scope="col">Email</th>}
              {visibleColumns.phoneNumber && <th scope="col">Phone Number</th>}
              {visibleColumns.avgSpend && <th scope="col">Avg. Spend</th>}
              {visibleColumns.visits && <th scope="col">Visits</th>}
              {visibleColumns.birthDay && <th scope="col">BirthDay</th>}
              {visibleColumns.createdOn && <th scope="col">Created On</th>}
              {visibleColumns.lastActivityOn && (
                <th scope="col">lastActivityOn</th>
              )}
            </tr>
          </thead>
          <tbody>
            {customersResults && customersResults.length > 0 ? (
              customersResults.map((customer) => (
                <tr
                  key={customer.referenceId}
                  onClick={toggleRightCanvas}
                  data-id={customer.id}
                >
                  {/* {
                    <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`marketingAllowedSwitch-${customer.referenceId}`}
                          checked={customer.optIn.marketing.allowed}
                          onChange={() =>
                            handleToggleMarketingAllowed(
                              customer.referenceId,
                              !customer.optIn.marketing.allowed
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`marketingAllowedSwitch-${customer.referenceId}`}
                        >
                          {customer.optIn.marketing.allowed
                            ? "Enabled"
                            : "Disabled"}
                        </label>
                      </div>
                    </td>
                  } */}
                  <td>{customer.firstName}</td>
                  {visibleColumns.lastName && <td>{customer.lastName}</td>}
                  {visibleColumns.email && <td>{customer.email}</td>}
                  {visibleColumns.phoneNumber && (
                    <td>{customer.phoneNumber}</td>
                  )}
                  {visibleColumns.avgSpend && (
                    <td>
                      ${" "}
                      {numeral(
                        Utility.getAvgSpend(
                          customer.totalTransactionsCount || 1,
                          customer.totalTransactionsSum || 0
                        )
                      ).format("0.00")}
                    </td>
                  )}
                  {visibleColumns.visits && (
                    <td>{customer.totalTransactionsCount}</td>
                  )}
                  {visibleColumns.birthDay && (
                    <td>
                      {customer.dateOfBirthDay
                        ? new Date(customer.dateOfBirthDay).toLocaleDateString()
                        : ""}
                    </td>
                  )}
                  {visibleColumns.createdOn && (
                    <td>
                      {customer.createdOn
                        ? new Date(customer.createdOn).toLocaleDateString()
                        : ""}
                    </td>
                  )}
                  {visibleColumns.lastActivityOn && (
                    <td>
                      {customer.lastPurchasedDate
                        ? new Date(
                            customer.lastPurchasedDate
                          ).toLocaleDateString()
                        : ""}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="text-center"
                  colSpan={Object.keys(visibleColumns).length}
                >
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing{" "}
            <span className="fw-semibold">
              {Math.min(customersCount, skip + limit)}
            </span>{" "}
            of <span className="fw-semibold">{customersCount}</span> Results
          </div>
        </div>
        <Pagination
          dataCount={customersCount}
          currentPage={skip / limit + 1}
          setCurrentPage={(pageNumber: number) => {
            setSkip(limit * (pageNumber - 1));
          }}
          perPageData={limit}
          // Remove setSkip and loadData fns
          setSkip={() => {}}
          loadData={() => {}}
        />
      </div>

      <UiOffcanvas
        isRight={isRight}
        toggleRightCanvas={toggleRightCanvas}
        customersResults={customersResults}
      />
    </>
  );
};

export default CustomersTable;
