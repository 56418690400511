import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useAuthStore, useOrganizationStore } from "../../../Store/store";
import moment from "moment";
import BreadCrumb from "../../Common/BreadCrumb";
import { UserRole } from "../../../Constants";

const ProfilePage = () => {
  document.title = "Profile | ShoutOUT";
  const [role] = useAuthStore((state) => [state.role]);
  const [organization] = useOrganizationStore((state) => [state.organization]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Profile" />
          <Row className="mb-3">
            <Col lg="12">
              <CardBody>
                <div className="d-flex">
                  <div className="mx-3">
                    <img
                      src={organization.logo}
                      alt="Avatar"
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <div className="flex-grow-1 align-self-center">
                    <div className="text-muted">
                      <h5>{organization.name || "Admin"}</h5>
                      <Row>
                        <Col>
                          <p className="mb-1">
                            Created On:{" "}
                            {moment(organization?.organizationCreatedOn).format(
                              "LL LTS"
                            )}
                          </p>
                        </Col>
                        <Col>
                          <p className="mb-1">
                            Updated On:{" "}
                            {moment(organization?.updatedOn).format("LL LTS")}
                          </p>
                        </Col>
                        <Col>
                          <p className="mb-1">
                            Clover Created On:{" "}
                            {moment(organization?.createdOn).format("LL LTS")}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Col>
          </Row>
          <Row className="m-3">
            <Col lg="12">
              <h6>Business Details</h6>
              <Card>
                <CardBody>
                  <h6 className="fw-bold">Address</h6>
                  <Row>
                    <Col lg="4" md="4" sm="12">
                      <p className="text-muted mb-1">
                        Address: {organization?.address?.address1}
                      </p>
                    </Col>
                    <Col lg="4" md="4" sm="12">
                      <p className="text-muted mb-1">
                        State: {organization?.address?.state}
                      </p>
                    </Col>
                    <Col lg="4" md="4" sm="12">
                      <p className="text-muted mb-1">
                        Country: {organization?.address?.country}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="4" md="4" sm="12">
                      <p className="text-muted mb-1">
                        City: {organization?.address?.city}
                      </p>
                    </Col>
                    <Col lg="4" md="4" sm="12">
                      <p className="text-muted mb-1">
                        Zip: {organization?.address?.zip}
                      </p>
                    </Col>
                    <Col lg="4" md="4" sm="12">
                      <p className="mb-1"></p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="4" md="4" sm="12">
                      <p className="mb-1">
                        <span className="fw-bold">Business Type: </span>
                        <span className="text-muted">{organization?.type}</span>
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="4" md="4" sm="12">
                      <p className="mb-1">
                        <span className="fw-bold">Time Zone: </span>
                        <span className="text-muted">
                          {organization?.timeZone}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="m-3">
            <Col lg="12">
              <h6>Admin Contact</h6>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="4" md="4" sm="12">
                      <p className="mb-1">
                        <span className="fw-bold">Name: </span>
                        <span className="text-muted">
                          {organization?.owner?.name}
                        </span>
                      </p>
                    </Col>
                    <Col lg="4" md="4" sm="12">
                      <p className="mb-1">
                        <span className="fw-bold">Email: </span>
                        <span className="text-muted">
                          {organization?.owner?.email}
                        </span>
                      </p>
                    </Col>
                    <Col lg="4" md="4" sm="12">
                      <p className="mb-1">
                        <span className="fw-bold">Phone Number: </span>
                        <span className="text-muted">
                          {organization?.phoneNumber}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="m-3">
            <Col lg="12">
              <h6>Employees</h6>
              <Card>
                <CardBody>
                  {organization?.metadata?.employees?.map((employee) => (
                    <div key={employee.id} className="mb-3">
                      <Col className="mb-1 fw-bold">{employee.name}</Col>
                      <Row>
                        {employee.email && (
                          <Col lg="6" md="6" sm="6" className="mb-0 text-muted">
                            Email: {employee.email}
                          </Col>
                        )}
                        <Col lg="6" md="6" sm="6" className="mb-0 text-muted">
                          Role: {employee.role}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {role === UserRole.SUPERADMIN && (
            <Row className="m-3">
              <Col lg="12">
                <h6>Billing Information</h6>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="4" md="4" sm="12">
                        <p className="mb-1">
                          <span className="text-muted">Billing Enabled: </span>
                          {organization?.metadata?.isBillable
                            ? "Enabled"
                            : "Disabled"}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

export default ProfilePage;
