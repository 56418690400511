import { useState, useEffect, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Badge
} from "reactstrap";
import classnames from "classnames";
import CustomerTabComponent from "./CustomerTabComponent";
import AllCustomerTabComponent from "./AllCustomerTabComponent";
import { useQuery } from "@tanstack/react-query";
import {
  getSegments,
  filterCustomersCount,
  getCustomersCount
} from "../../../services";
import { useAuthStore, useCustomersStore } from "../../../Store/store";
import {
  CustomersFilterTasks,
  useCustomers,
  useCustomersCount
} from "./context/CustomerDataContext";
import { Utility } from "../../../utils";
import { filterConfig } from "./CustomersFilterConfig";
import { PreDefinedSegments } from "./Constants";
import CustomerSegmentDropdown from "./CustomerSegmentDropdown";

const CustomerTabs = () => {
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);

  const [limit, skip, setQueryTask] = useCustomersStore((state) => [
    state.limit,
    state.skip,
    state.setQueryTask,
    state.queryTask
  ]);
  const {
    customers: customersList,
    isLoadingCustomers,
    isFetchingCustomersData
  } = useCustomers();
  const { customersCount, isLoadingCustomersCount, isFetchingCustomersCount } =
    useCustomersCount();

  const [activeTab, setactiveTab] = useState("all");
  const [segmentViseCustomersCount, setSegmentViseCustomersCount] = useState(
    []
  );
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  // eslint-disable-next-line
  const [limitSegments, setLimitSegments] = useState(100);
  // eslint-disable-next-line
  const [skipSegments, setSkipSegments] = useState(0);

  const loadSegmentsData = () => {
    const queryObj = {
      limit: limitSegments,
      skip: skipSegments
    };
    return getSegments(queryObj);
  };

  const {
    data: segmentsData,
    isLoading,
    refetch
  } = useQuery<any>({
    queryKey: ["segments", skipSegments],
    queryFn: loadSegmentsData,
    enabled: isLoggedIn(),
    refetchOnWindowFocus: false
  });

  const allSegments = useMemo(() => {
    if (!isLoading && segmentsData?.items.length > 0) {
      return segmentsData?.items;
    }
  }, [segmentsData, isLoading]);

  useEffect(() => {
    if (activeTab !== "all") {
      const currentTab =
        PreDefinedSegments.find((segment) => segment?.id === activeTab) ||
        allSegments?.find((segment) => segment?.id === activeTab);

      const customerFilters = Utility.getMongoDBQuery(
        currentTab?.filter,
        filterConfig
      );

      setQueryTask({
        task: CustomersFilterTasks.filterCustomers,
        query: { filterObj: customerFilters }
      });
    } else {
      setQueryTask({
        task: CustomersFilterTasks.getAllCustomers,
        query: {}
      });
    }
  }, [activeTab, allSegments]);
  const { data: totalCustomers, isLoading: isLoadingTotalCustomers } =
    useQuery<number>({
      queryKey: ["customerCount"],
      queryFn: async () => {
        const response = await getCustomersCount({});
        return response?.count;
      },
      enabled: isLoggedIn,
      refetchOnWindowFocus: false
    });

  const getCustomersCountInSegment = useCallback(async (filterObj) => {
    const customerFilters = Utility.getMongoDBQuery(filterObj, filterConfig);
    const res = await filterCustomersCount({ filterObj: customerFilters });
    return res.count ?? 0;
  }, []);

  const loadSegmentCounts = useCallback(async () => {
    if (!PreDefinedSegments) return [];

    const counts = await Promise.all(
      PreDefinedSegments.map(async (segment) => {
        const count = await getCustomersCountInSegment(segment.filter);
        return { id: segment.id, count };
      })
    );

    setSegmentViseCustomersCount(counts);
  }, [PreDefinedSegments, getCustomersCountInSegment]);

  useEffect(() => {
    if (PreDefinedSegments) {
      loadSegmentCounts();
    }
  }, [PreDefinedSegments]);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs mb-3">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "all"
                    })}
                    onClick={() => {
                      toggleTab("all");
                    }}
                  >
                    All Customers
                    <Badge color="primary" className="mx-2">
                      {String(totalCustomers)}
                    </Badge>
                  </NavLink>
                </NavItem>
                {PreDefinedSegments.map((segment) => {
                  const countObj =
                    segmentViseCustomersCount &&
                    segmentViseCustomersCount.find(
                      (preSegment) => preSegment.id === segment.id
                    );
                  return (
                    <NavItem key={segment.id}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === segment?.id
                        })}
                        onClick={() => toggleTab(segment?.id)}
                      >
                        {segment?.name}
                        <Badge color="primary" className="mx-2">
                          {countObj ? countObj.count : 0}
                        </Badge>
                      </NavLink>
                    </NavItem>
                  );
                })}
                <CustomerSegmentDropdown
                  allSegments={allSegments}
                  toggleTab={toggleTab}
                  isAllCustomers={false}
                  title="+ More Segments"
                  selectedSegmentName=""
                />
              </Nav>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="all" id="All Customers">
                  <AllCustomerTabComponent
                    handleSegmentCreationSuccess={refetch}
                    limit={limit}
                    skip={skip}
                    setQueryTask={setQueryTask}
                    customersList={customersList}
                    isLoadingCustomers={isLoadingCustomers}
                    isFetchingCustomersData={isFetchingCustomersData}
                    customersCount={customersCount}
                    isLoadingCustomersCount={isLoadingCustomersCount}
                    isFetchingCustomersCount={isFetchingCustomersCount}
                  />
                </TabPane>

                {PreDefinedSegments.concat(allSegments || []).map((segment) => (
                  <TabPane
                    key={segment.id}
                    tabId={segment.id}
                    id={segment.name}
                  >
                    {segment?.description && (
                      <Card className="border shadow-none">
                        <CardBody>
                          <h6 className="mb-4 mt-2">{segment.name}</h6>
                          {segment?.description}
                        </CardBody>
                      </Card>
                    )}
                    <CustomerTabComponent
                      customersList={customersList}
                      isLoadingCustomers={isLoadingCustomers}
                      customersCount={customersCount}
                      isLoadingCustomersCount={isLoadingCustomersCount}
                    />
                  </TabPane>
                ))}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomerTabs;
