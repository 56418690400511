import { Container } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import CustomerTabs from "./CustomerTabs";

const CustomerPage = () => {
  document.title = "Customers List | MarketBuzz";

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Customers List" pageTitle="Customers" />
          <CustomerTabs />
        </Container>
      </div>
    </>
  );
};

export default CustomerPage;
