import { useState, useCallback, useMemo } from "react";
import {
  searchOragnizations,
  getConfigurationData,
  filterOragnizations
} from "../../../services";
import AdvanceSearchOption from "../../../Components/Common/AdvanceSearchOption";
import OrganizationsTable from "./OrganizationsTable";
import { Container } from "reactstrap";
import { TOrganizationModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import FilterDropdown from "./FilterDropdown";
import { useQuery } from "@tanstack/react-query";

const SwitchAccount = () => {
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [organizationSearchData, setOrganizationSearchData] = useState<
    TOrganizationModelJSON[]
  >([]);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const [limit, setLimit] = useState<number>(10);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const [skip, setSkip] = useState<number>(0);
  const [selectedApplication, setSelectedApplication] = useState("");

  const { data: configData, isLoading } = useQuery({
    queryKey: ["config"],
    queryFn: getConfigurationData
  });

  const searchOrganizations = useCallback(
    async (query: string) => {
      const queryObj = {
        limit,
        skip,
        query: query
      };

      const res = await searchOragnizations(queryObj);

      setIsSearchResult(true);
      setOrganizationSearchData(res.items);
      return res.items;
    },
    [limit, skip]
  );

  const onSelectApplication = useCallback(
    async (applicationId) => {
      setSelectedApplication(applicationId);
      const queryObj = {
        limit,
        skip,
        query: applicationId
      };

      const res = await filterOragnizations(queryObj);
      setIsSearchResult(true);
      setOrganizationSearchData(res.items);
    },
    [
      setSelectedApplication,
      limit,
      skip,
      setOrganizationSearchData,
      setIsSearchResult
    ]
  );

  const dropdownItems = useMemo(() => {
    return Object.entries(configData?.clover?.apps || {}).map(([id, app]) => ({
      id,
      name: app.appName
    }));
  }, [configData?.clover?.apps]);

  return (
    <div className="page-content d-flex justify-content-center align-items-center">
      <Container>
        <h1>Switch account</h1>
        <div className="d-flex justify-content-between">
          <AdvanceSearchOption handleSearch={searchOrganizations} />
          <FilterDropdown
            dropdownItems={dropdownItems || []}
            toggleTab={onSelectApplication}
            title="Select Application"
          />
        </div>
        <OrganizationsTable
          searchResults={organizationSearchData as []}
          isSearchResult={isSearchResult}
        />
      </Container>
    </div>
  );
};
export default SwitchAccount;
