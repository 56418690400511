import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Toast, ToastHeader, ToastBody, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";
import {
  useSideBarVisibilityStore,
  useOrganizationStore,
  useConfigStore
} from "../Store/store";
import { Utility } from "../utils";
const refreshIntervalInMilliSeconds = 5000;

const Layout = (props: any) => {
  const [headerClass, setHeaderClass] = useState("");
  const [syncDataResponse] = useOrganizationStore((state) => [
    state.syncDataResponse
  ]);
  const [isSynced, setIsSynced] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [
    sidebarVisibilitytype,
    layoutType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarType
  ] = useSideBarVisibilityStore((state) => [
    state.sidebarVisibilityType,
    state.layoutType,
    state.layoutModeType,
    state.layoutWidthType,
    state.layoutPositionType,
    state.topbarThemeType,
    state.leftsidbarSizeType,
    state.leftSidebarViewType,
    state.leftSidebarType
  ]);

  const [config] = useConfigStore((state) => [state.config]);

  const primaryRGB = useMemo(() => {
    const primaryColor = config?.primaryColor;
    const color = primaryColor
      ? Utility.hexToRGB(primaryColor)
      : "18, 143, 139";
    return color;
  }, [config?.primaryColor]);

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }
  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      sidebarVisibilitytype
    ) {
      window.dispatchEvent(new Event("resize"));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    sidebarVisibilitytype
  ]);

  useEffect(() => {
    if (sidebarVisibilitytype === "show" || layoutType === "vertical") {
      document.querySelector(".hamburger-icon")?.classList.remove("open");
    } else {
      document.querySelector(".hamburger-icon")?.classList.add("open");
    }
  }, [sidebarVisibilitytype, layoutType]);

  useEffect(() => {
    const { customer, orders } = syncDataResponse;
    if (customer === 100 && orders === 100) {
      setIsSynced(true);
    } else {
      setIsSynced(false);
    }
  }, [syncDataResponse]);

  const handleDataSyncProgress = useCallback(() => {
    const { customer, orders } = syncDataResponse;

    switch (true) {
      case customer >= 0 && customer <= 100:
        return `Customer data sync in progress - ${customer}%`;

      case orders >= 0 && orders <= 100:
        return `Transaction data sync in progress - ${orders}%`;

      case customer === 100 && orders === 100:
        return "All syncing completed";

      default:
        return "Data sync failed";
    }
  }, [syncDataResponse]);

  // Ensure the toast only appears if the sync is in progress, if not, gets removed in 5s timeout
  useEffect(() => {
    if (isSynced) {
      const timer = setTimeout(
        () => setIsVisible(false),
        refreshIntervalInMilliSeconds
      );
      return () => clearTimeout(timer);
    } else {
      setIsVisible(true);
    }
  }, [isSynced]);

  return (
    <React.Fragment>
      <div
        id="layout-wrapper"
        style={
          {
            "--vz-primary": `${config?.primaryColor}`,
            ...(config?.primaryHoverColor && {
              "--vz-primary-text-emphasis": `${config?.primaryHoverColor}`
            }),
            "--vz-secondary": `${config?.secondaryColor}`,
            "--primary-color": `${config?.primaryColor}`,
            "--vz-card-bg": `${config?.primaryColor}`,
            "--vz-primary-rgb": `${primaryRGB}`,
            "--vz-link-color-rgb": `${primaryRGB}`,
            "--vz-vertical-menu-item-active-color": `${config?.primaryColor}`,
            "--vz-vertical-menu-item-hover-color": `${config?.primaryColor}`,
            "--vz-nav-link-hover-color": `${config?.primaryColor}`,
            "--vz-nav-link-color": `${config?.primaryColor}`,
            "--vz-link-color": `${config?.primaryColor}`,
            "--vz-link-hover-color": `${config?.primaryColor}`,
            "--vz-vertical-menu-sub-item-hover-color": `${config?.primaryColor}`,
            "--vz-vertical-menu-sub-item-active-color": `${config?.primaryColor}`,
            "--toast-border-color": `${config?.primaryColor}`
          } as React.CSSProperties
        }
      >
        <Header headerClass={headerClass} layoutModeType={layoutModeType} />
        <Sidebar />
        <div className="main-content">
          <div className="toast-margin-custom">
            <Toast isOpen={isVisible} className="toast-border-primary mb-3">
              <ToastHeader icon={!isSynced && <Spinner size="sm" />}>
                {isSynced ? "Data synced" : "Data sync in progress"}
              </ToastHeader>
              <ToastBody>{handleDataSyncProgress()}</ToastBody>
            </Toast>
          </div>
          {props.children}
          {/* <Footer /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object
};

export default Layout;
